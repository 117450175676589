<!--生成海报组件-->
<!--传入二维码url和海报链接自动生成海报-->
<template>
    <div>
        <canvas id="poster" class="poster"></canvas>
        <vue-qr id="qr" :text="url" style="display: none;"></vue-qr>
        <div id="lastPoster" class="last-poster"></div>
    </div>
</template>

<script>
  import VueQr from 'vue-qr'
  export default {
    name: 'QrPoster',
    props: ['url', 'poster', 'text', 'qrPercentWidth', 'qrPercentHeight', 'qrPercentX', 'qrPercentY', 'textPercentX', 'textPercentY', 'textFontSize', 'render'],
    data () {
      return {
        newUrl: '',
        bgLoadFinish: false, // 生成小程序码的时候用到
        wxappQrLoadFinish: false //  生成小程序码的时候用到
      }
    },
    methods: {
      // 生成海报
      createPoster: function () {
        var that = this
        // 获取canvas对象
        const canvas = document.getElementById('poster')
        const ctx = canvas.getContext('2d')
        const ratio = this.getPixelRatio(ctx)
        // 定义一张图片对象，传入海报预缓存；这边会跨域，需要处理；腾讯云；
        const img = new Image()
        // img.crossOrigin = 'Anonymous'
        img.setAttribute('crossOrigin', 'Anonymous')
        img.width = 108
        img.height = 192
        // 设置画布宽高
        canvas.width = window.screen.availWidth * ratio
        canvas.height = (window.screen.availWidth * 16 / 9) * ratio
        // 取得二维码 20210831添加微信小程序码的判断
        //  小程序码和背景是两张图片，存在加载先后顺序的问题
        let qr = ''
        if (this.render === 'wxappqr') {
          qr = new Image()
          qr.setAttribute('crossOrigin', 'Anonymous')
          qr.width = 100
          qr.height = 100
          qr.src = 'https://seetop-1257860468.file.myqcloud.com/seetop/uploads/' + this.url
          qr.onload = function () {
            that.wxappQrLoadFinish = true
            that.wxappQrLoadFunc(ctx, img, qr, ratio, canvas)
          }
          img.onload = function () {
            that.bgLoadFinish = true
            that.wxappQrLoadFunc(ctx, img, qr, ratio, canvas)
          }
        } else {
          qr = document.getElementById('qr')
          img.onload = function () {
            that.drawFunc(ctx, img, qr, ratio, canvas)
          }
        }
        // 海报加载完，对canvas进行出来
        // img.onload = function () {
        //   console.log('背景海报加载完成')
        //   that.drawFunc(ctx, img, qr, ratio, canvas)
        //   // // 画海报
        //   // ctx.drawImage(img, 0, 0, window.screen.availWidth * ratio, ((window.screen.availWidth * 16) / 9) * ratio)
        //   // // 画二维码
        //   // // 商品分销海报的参数
        //   // // ctx.drawImage(qr, (window.screen.availWidth * 0.7502) * ratio, (window.screen.availWidth * 1.471) * ratio, ((window.screen.availWidth * 0.185)) * ratio, ((window.screen.availWidth * 0.185)) * ratio)
        //   // ctx.drawImage(qr, window.screen.availWidth * that.qrPercentX * ratio, window.screen.availWidth * that.qrPercentY * ratio, that.qrPercentWidth * window.screen.availWidth * ratio, that.qrPercentWidth * window.screen.availWidth * ratio)
        //   // ctx.font = that.textFontSize + 'px Courier New'
        //   // ctx.textAlign = 'center'
        //   // // 写入用户姓名
        //   // // 商品分销海报的参数
        //   // // ctx.fillText(that.text, window.screen.availWidth * 0.838 * ratio, window.screen.availWidth * 1.689 * ratio)
        //   // ctx.fillText(that.text, window.screen.availWidth * that.textPercentX * ratio, window.screen.availWidth * that.textPercentY * ratio)
        //   // // canvas转图片
        //   // const showPoster = document.getElementById('lastPoster')
        //   // const test = that.convertCanvasToImage(canvas)
        //   // showPoster.appendChild(test)
        // }
        img.src = that.poster
      },
      // 获取屏幕比例
      getPixelRatio: function (context) {
        const backingStore = context.backingStorePixelRatio ||
          context.webkitBackingStorePixelRatio ||
          context.mozBackingStorePixelRatio ||
          context.msBackingStorePixelRatio ||
          context.oBackingStorePixelRatio ||
          context.backingStorePixelRatio || 1
        return (window.devicePixelRatio || 1) / backingStore
      },
      convertCanvasToImage: function (canvas) {
        const image = new Image()
        // image.crossOrigin = 'Anonymous'
        image.setAttribute('crossOrigin', 'Anonymous')
        image.src = canvas.toDataURL('image/png')
        image.style = 'width: 100%;'
        return image
      },
      wxappQrLoadFunc: function (ctx, img, qr, ratio, canvas) {
        if (this.wxappQrLoadFinish && this.bgLoadFinish) {
          this.drawFunc(ctx, img, qr, ratio, canvas)
        }
      },
      drawFunc: function (ctx, img, qr, ratio, canvas) {
        ctx.drawImage(img, 0, 0, window.screen.availWidth * ratio, ((window.screen.availWidth * 16) / 9) * ratio)
        // 画二维码
        // 商品分销海报的参数
        // ctx.drawImage(qr, (window.screen.availWidth * 0.7502) * ratio, (window.screen.availWidth * 1.471) * ratio, ((window.screen.availWidth * 0.185)) * ratio, ((window.screen.availWidth * 0.185)) * ratio)
        ctx.drawImage(qr, window.screen.availWidth * this.qrPercentX * ratio, window.screen.availWidth * this.qrPercentY * ratio, this.qrPercentWidth * window.screen.availWidth * ratio, this.qrPercentWidth * window.screen.availWidth * ratio)
        ctx.font = this.textFontSize + 'px Courier New'
        ctx.textAlign = 'center'
        // 写入用户姓名
        // 商品分销海报的参数
        // ctx.fillText(this.text, window.screen.availWidth * 0.838 * ratio, window.screen.availWidth * 1.689 * ratio)
        ctx.fillText(this.text, window.screen.availWidth * this.textPercentX * ratio, window.screen.availWidth * this.textPercentY * ratio)
        // canvas转图片
        const showPoster = document.getElementById('lastPoster')
        const test = this.convertCanvasToImage(canvas)
        showPoster.appendChild(test)
      }
    },
    watch: {
    },
    components: { VueQr },
    mounted () {
      // setTimeout(this.createPoster, 1000)
      this.createPoster()
    }
  }
</script>

<style scoped>
    .poster {
        width: 100%;
        height: 100%;
        display: none;
    }
    .last-poster {
        width: 100%;
        height: 100%;
    }
</style>
