<!--邀请海报-->
<template>
    <div>
        <qr-poster
                v-if="channel"
                :url="webUrl + '#/distribution/apply/' + channel"
                poster="https://seetop-1257860468.file.myqcloud.com/html/distributor/banner20200325001.jpg"
                text=""
                qrPercentWidth="0.35"
                qrPercentHeight="0.35"
                qrPercentX="0.325"
                qrPercentY="0.82"
                textPercentX="0.51"
                textPercentY="1.07"
                textFontSize="25"
        ></qr-poster>
    </div>
</template>

<script>
  import QrPoster from '../../components/common/QrPoster'
  import { globalConfig } from '../../utils/mixin'
  export default {
    name: 'Poster',
    components: { QrPoster },
    mixins: [globalConfig],
    data () {
      return {
        channel: ''
      }
    },
    methods: {
      init: function () {
        this.axios.post(this.apiUrl + 'mall/distributor/getChannelInit').then((response) => {
          const data = response.data
          if (data.info.length === 0) {
            const that = this
            this.$toast({
              message: '用户异常',
              onClose: function () {
                that.$router.replace('/user')
              }
            })
          } else {
            if (data.info.status !== '1') {
              this.$router.push('/user')
            }
            this.channel = data.info.id
          }
        })
      }
    },
    created () {
      this.init()
    }
  }
</script>

<style scoped>

</style>
